.chat-dialog {
    width: 700px;
    height: 400px;
    overflow-y: scroll;
    background-color: white;
    margin-left: auto;
    margin-right: auto;

    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */


    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    @media (max-width: 764px) {
        width: 100%;

        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
    }
}

.chat-dialog::-webkit-scrollbar {
    display: none;
}

#chat-placeholder {
    height: 0px;
}
  
.communication {
    width: 700px;
    margin-left: auto;
    margin-right: auto;
    background-color: white;
    padding: 0;

    @media (max-width: 764px) {
        width: 100%;
    }

    input {
        display: inline-block;
        margin-right: auto;
        margin-left: 2px;
        padding: 0;
        width: 600px;
        height: 48px;
        font-size: 1.2rem;
        border: none;
        outline: none;
        vertical-align: middle;

        @media (max-width: 764px) {
            width: 80%;
        }
    }
    button {
        display: inline-block;
        width: 50px;
        height: 50px;
        border-radius: 10px;
        font-size: 1.2rem;
        border: none;
        vertical-align: middle;
        background-color: white;
        color: white;
        img {
            width: 40px;
            height: 40px;
        }
        @media (max-width: 764px) {
            width: 40px;
            height: 40px;
            padding: 0;
            img {
                width: 30px;
                height: 30px;
            }
        }
    }
    button:hover {
        cursor: pointer;
        background-color: #1b8baa;
        color: black;
    }
}