.infos {
    width: 100vw;
}

.teaser {
    width: 800px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 100px;
    @media (max-width: 768px) {
        width: 100%;
    }
}

.functionality {
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 100px;
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 20px;
    width: 690px;
    padding-bottom: 25px;
    @media (max-width: 768px) {
        width: 90%;
        padding: 0;
        float: none;
        margin-top: 20px;
        padding-bottom: 20px;
    }

    .functionality-icon {
        width: 100px;
        height: 100px;
        margin-bottom: 10px;
        margin-left: auto;
        margin-right: auto;
        @media (max-width: 768px) {
            width: 80px;
            height: 80px;
        }
        img {
            margin-top: 20px;
            width: 100%;
            height: 100%;

            @media (max-width: 768px) {
                margin-top: 10px;
            }
        }
    }

    .functionality-text {
        width: 90%;
        max-width: 40rem;
        text-align: justify;
        margin-top: 30px;
        margin-left: auto;
        margin-right: auto;
        font-size: 1.2rem;
        font-family: "Poppins";

        @media (max-width: 768px) {
            width: 80%;
            float: none;
            text-align: justify;
            margin-top: 20px;
        }
    }

}