.selling-point {
    display: inline-block;
    width: 30%;
    margin: 15px;
    padding: 5px;
    text-align: center;
    border-radius: 10px;
    font-family: "Poppins";
    line-height: 1.5em;
    width: 200px;
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 20px;
    @media (max-width: 768px) {
        width: 100%;
        float: none;
    }
    img {
        width: 100px;
        height: 100px;
        margin-bottom: 10px;
    }

    @media (max-width: 768px) {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        display: block;
        font-size: 1.2em;
        color: white;
        img {
            width: 70px;
            height: auto;
            margin-bottom: 5px;
        }
    }

    .text {
        color: black;
        font-size: 1.2rem;
        font-family: "Poppins";
    }
}