.question {
    background-color: rgb(66, 66, 255);
    color: white;
    border-radius: 8px;
    width: 250px;
    margin-left: auto;
    margin-right: 20px;
    margin-top: 5px;
    margin-bottom: 5px;
    text-align: right;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    word-break: break-word;
    word-wrap: normal;
}