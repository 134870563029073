@font-face {
    font-family: "Anton";
    src: url("../fonts/Anton-Regular.ttf");
}

@font-face {
    font-family: "Rubik";
    src: url("../fonts/Rubik-VariableFont_wght.ttf");
}

@font-face {
    font-family: "Poppins";
    src: url("../fonts/Poppins-Regular.ttf");
}

.landingpage {
    width: 100vw;
    height: 100vh;

    #more-indicator {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 0);
        font-size: 3rem;
        font-weight: 700;
        color: black;
        margin-bottom: 1rem;
    }
}

.title {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 1rem;

    #headline {
        font-family: "Poppins";
        font-size: 3rem;
        font-weight: 700;
        color: white;
        margin-bottom: 1rem;
    }

    #sub-headline {
        font-family: "Poppins";
        font-size: 2rem;
        font-weight: 700;
        color: white;
        margin-bottom: 1rem;

        @media (max-width: 768px) {
            font-size: 1.2rem;
        }
    }

    @media (max-width: 768px) {
        top: 45%;
        left: 50%;
    } 
}

#shortcut-buttons {
    position: relative;
    height: 70px;
    width: 640px;
    margin-left: auto;
    margin-right: auto;
    font-size: 3rem;
    font-weight: 700;    
    margin-top: 100px;

    @media (max-width: 768px) {
        width: 270px;
        height: 150px;
        float: none;
        margin-top: 50px;
    }

    .shortcut-button {
        height: 90px;
        float: left;
        width: 300px;
        border-radius: 10px;
        font-size: 1.2rem;
        border: none;
        background-color: rgba(60, 60, 60, 0.618);
        color: white;
        margin-left: 10px;
        margin-right: 10px;
        padding-top: 10px;

        @media (max-width: 768px) {
            height: 70px;
            width: 100%;
            float: none;
            margin-top: 10px;
            margin-left: 0px;
            margin-right: 0px;
            padding-top: 5px;
        }

        .shortcut-icon {
            width: 50px;
            height: 50px;
            margin-left: auto;
            margin-right: auto;

            @media (max-width: 768px) {
                width: 40px;
                height: 40px;
            }

            img {
                width: 100%;
                height: 100%;
            }
        }
        .shortcut-text {
            text-align: center;
        }
    }

    .shortcut-button:hover {
        cursor: pointer;
        background-color: rgba(192, 192, 192, 0.8);
        color: rgb(0, 0, 0);
    }
}