.answer {
    background-color: rgb(223, 223, 223);
    color: black;
    border-radius: 10px;
    width: 250px;
    text-align: left;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    word-break: break-word;
    margin-top: 10px;
    margin-left: 20px;
}